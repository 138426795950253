import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import NewsletterBanner from "../components/newsletter-banner";

const DonatePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Donate-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Donate",
				item: {
					url: `${siteUrl}/donate`,
					id: `${siteUrl}/donate`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Donate | The Ella Roberta Foundation"
				description="Help us campaign for a world where everyone can breathe air that is free from toxic pollution, regardless of where they live, their economic status or their ethnic background."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/donate`,
					title: "Donate | The Ella Roberta Foundation",
					description:
						"Help us campaign for a world where everyone can breathe air that is free from toxic pollution, regardless of where they live, their economic status or their ethnic background.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-background">
					<Hero
						title={<span>DONATE</span>}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="bg-primary py-5 py-lg-8">
						<Container>
							<Row className="justify-content-center">
								<Col className="text-center">
									<p className="fs-3  text-white ">
										Help us campaign for a world where everyone can breathe air
										that is free from toxic pollution, regardless of where they
										live, their economic status or their ethnic background.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-8">
						<Container>
							<Row className="justify-content-center gx-lg-5">
								{/* <Col className="pb-5 pb-ld-0" lg={6}>
									<h2 className="fs-1 text-primary pb-4">A MEMORIAL STATUE</h2>
									<p>
										We’re crowdfunding for a permanent memorial of Ella. It will
										be a statue made from pollution-absorbing material, to make
										the invisible threat of air pollution more visible. It will
										be permanently exhibited in Mountsfield Park, Lewisham, near
										where Ella used to live.
									</p>
									<Button
										className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
										variant="outline-black"
										onClick={() =>
											alert(
												"This page is now closed as we have reached our fundraising goal."
											)
										}
										// href="https://www.gofundme.com/f/first-breathe-for-our-future-ancestor"
									>
										DONATE
									</Button>
								</Col> */}
								<Col lg={6}>
									<h2 className="fs-1 text-primary pb-4">
										FUNDRAISING FOR ELLA
									</h2>
									<p>
										Help the Foundation raise money so that it can continue its
										work raising awareness of the health impact of air pollution
										in children. Your money ensures we can continue telling
										Ella’s story and try to reduce the risk of air pollution for
										other children.
									</p>
									<Button
										className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
										variant="outline-black"
										target="_blank"
										rel="noreferrer"
										href="https://www.justgiving.com/ellaroberta-familyfoundation"
									>
										DONATE
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					{/* <NewsletterBanner /> */}
				</div>
			</Layout>
		</>
	);
};

export default DonatePage;
